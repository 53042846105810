import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"

import photo from "../images/photo.png"
import { to_mobile_view } from "../constants"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/section"
// import PostTitle from '../components/postTitle'
import Projects from "../components/projectCards"
import Skills from "../components/skillCards"

const h_to_v_image = "1100px"

const HeaderLink = styled(Link)`
  color: rgba(0, 0, 0, 0.85);

  &:hover {
    text-decoration: none;
  }
`

const Header = styled.h1`
  margin-top: 0.5em;
`

const HBox = styled.div`
  display: flex;
  flex-direction: row;
  height: 75vh;
  justify-content: space-evenly;

  @media screen and (max-width: ${h_to_v_image}) {
    height: 100%;
    flex-direction: column;
    margin-left: 1.5em;
    overflow-y: hidden;
  }
`
const VBox = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  margin: 3em;
  align-self: center;
  justify-content: center;

  @media screen and (max-width: ${to_mobile_view}) {
    height: auto;
    overflow-x: auto;
    align-items: center;
    margin: 2em;
  }
`

const SpotlightImage = styled.img`
  max-width: 30%;
  height: auto;
  margin-top: auto;
  margin-bottom: auto;

  @media screen and (max-width: ${h_to_v_image}) {
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
  }
`

const DynamicH1 = styled.h1`
  @media screen and (max-width: ${to_mobile_view}) {
    font-size: 8vw;
  }
`

const DynamicH2 = styled.h2`
  @media screen and (max-width: ${to_mobile_view}) {
    font-size: 4vw;
  }
`

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  // const Posts = edges
  // .filter(edge => edge.node.frontmatter.publish === true) // You can filter your posts based on some criteria
  // .map(edge => <PostTitle key={edge.node.id} post={edge.node} />)

  return (
    <Layout>
      <SEO title="Home" />
      <Section backgroundColor={"#63b7af"} height={"auto"}>
        <HBox>
          <SpotlightImage alt="Ian" src={photo} />
          <VBox>
            <DynamicH1>Hello! I am Ian Goh.</DynamicH1>
            <DynamicH2>Curious · Experimental · Adaptable</DynamicH2>
          </VBox>
        </HBox>
      </Section>
      <Section backgroundColor={"#abf0e9"} height={"auto"} id={"projects"}>
        <Header>
          <HeaderLink to="/projects">Projects</HeaderLink>
        </Header>
        <Projects />
      </Section>
      <Section backgroundColor={"#d4f3ef"} height={"auto"} id={"skills"}>
        <Header>
          <HeaderLink to="/about">Skills</HeaderLink>
        </Header>
        <Skills />
      </Section>
      {/* <Section backgroundColor={'#ee8572'}>
        <h1>Section 2</h1>
      </Section> */}
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 5
      filter: { frontmatter: { publish: { eq: true } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            publish
          }
        }
      }
    }
  }
`
