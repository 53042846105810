import React from "react"
import styled from "styled-components"

const StyledH3 = styled.h3`
  width: 100%;
  margin-top: 2em;
  margin-bottom: 0.5em;
`

const SkillBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-bottom: 2em;
`

const SkillCard = styled.div`
  border-radius: 10px;
  margin-top: 1em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  display: flex;
  min-height: 150px;
  height: auto;
  width: 350px;
  background-color: white;
  padding-top: 2em;
  padding-bottom: 2em;
  flex-direction: column;
  color: black;
  align-items: center;
`

const ImageCard = styled.div`
  display: flex;
  max-height: 250px;
  max-width: 250px;
`

const FeatureImage = styled.img`
  overflow-x: hidden;
  display: block;
  width: auto;
  height: auto;
  margin: 0;
`

const SkillName = styled.h2`
  color: black;
  margin: 0.5em 0;
`
const SkillNameCentered = styled.h2`
  color: black;
  margin-top: auto;
  margin-bottom: auto;
`

const Description = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
  overflow-y: scroll;
  margin: 0;
`

const ProjectLink = styled.a`
`

const Skills = () => (
  <SkillBox>
    <StyledH3 style={{'margin-top': '0em'}}>Intermediate Level</StyledH3>
    <SkillCard>
      <SkillName>Python</SkillName>
      <Description>
        <ProjectLink href="/projects/recyclace">Recyclace</ProjectLink>
        <ProjectLink href="/demo/hangman">Hangman</ProjectLink>
        <ProjectLink href="/projects/remember_grocery_bot">Remember Grocery Bot</ProjectLink>
      </Description>
    </SkillCard>
    <SkillCard>
      <SkillName>React</SkillName>
      <Description>
          <ProjectLink href="https://coin.iangohy.com/">Coin Toss</ProjectLink>
          <ProjectLink href="https://ttt.iangohy.com/">Tic-Tac-Toe</ProjectLink>
      </Description>
    </SkillCard>
    <SkillCard>
      <SkillName>Google Cloud Functions</SkillName>
      <Description>
        <ProjectLink href="/demo/remember_grocery_bot">Remember Grocery Bot</ProjectLink>
      </Description>
    </SkillCard>
    <SkillCard>
      <SkillName>Arduino</SkillName>
      <Description>
          <ProjectLink href="/demo/simple_robot_arm">Simple Robot Arm</ProjectLink>
      </Description>
    </SkillCard>
    <SkillCard>
      <SkillName>Node.js</SkillName>
      <Description>
        <ProjectLink href="/projects/annoying_bot">Annoying Bot</ProjectLink>
      </Description>
    </SkillCard>
    <SkillCard>
      <SkillNameCentered>Java Android App Development</SkillNameCentered>
        <Description>
          <ProjectLink href="https://istd.sutd.edu.sg/term4-design-exhibition/50001/wheregottimefind">wHeReGotTimeFind?!</ProjectLink>
        </Description>
    </SkillCard>
    <SkillCard>
      <SkillNameCentered>Java</SkillNameCentered>
    </SkillCard>

    <StyledH3>Beginner Level</StyledH3>
    <SkillCard>
      <SkillName>Zilliqa/Scilla</SkillName>
      <Description>
        <ProjectLink href="/demo/zil-poc">Zilliqa Review Federation</ProjectLink>
      </Description>
    </SkillCard>
    <SkillCard>
      <SkillName>Google App Engine</SkillName>
      <Description>
        <ProjectLink href="/projects/annoying_bot">Annoying Bot</ProjectLink>
      </Description>
    </SkillCard>
    <SkillCard>
      <SkillNameCentered>C++</SkillNameCentered>
    </SkillCard>
    <SkillCard>
      <SkillNameCentered>Docker</SkillNameCentered>
    </SkillCard>
    <SkillCard>
      <SkillNameCentered>Spring Framework</SkillNameCentered>
    </SkillCard>
    <SkillCard>
      <SkillNameCentered>VS Code Extensions</SkillNameCentered>
      <Description>
        <ProjectLink href="https://github.com/iangohy/compstruct-vscode">compstruct-vscode contribution</ProjectLink>
      </Description>
    </SkillCard>

    <StyledH3>Currently learning and experimenting with</StyledH3>
    <SkillCard>
      <SkillNameCentered>Kotlin</SkillNameCentered>
    </SkillCard>
  </SkillBox>
)

export default Skills
