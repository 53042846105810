import React from "react"
import styled from "styled-components"
import recyclaceImg from "../images/recyclace.jpg"
import annoyingBotImg from "../images/annoying_bot.jpg"
import logoImg from "../images/logo.png"
import rmbgrocerybotImg from "../images/remembergrocerybot.png"
import coinImg from "../images/coin.png"
import tttImg from "../images/ttt.png"
import parkthereImg from "../images/ParkThere_2.jpg"

const ProjectBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 2em 0;
`

const ProjectCard = styled.a`
  display: flex;
  height: 600px;
  width: 400px;
  background-color: white;
  margin: 1em;
  padding-top: 2em;
  flex-direction: column;
  color: black;
  align-items: center;

  &:hover {
    padding-top: 1.5em;
    text-decoration: none;
  }
`

const ImageCard = styled.div`
  display: flex;
  max-height: 250px;
  max-width: 250px;
`

const FeatureImage = styled.img`
  overflow-x: hidden;
  display: block;
  width: auto;
  height: auto;
  margin: 0;
`

const Title = styled.h2`
  color: black;
  margin: 0.5em 0;
`

const Description = styled.p`
  padding-top: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
  overflow-y: scroll;
  margin: 0;
`

const Projects = () => (
  <ProjectBox>
    <ProjectCard href="/projects/ParkThere">
      <ImageCard>
        <FeatureImage src={parkthereImg} />
      </ImageCard>
      <Title>ParkThere</Title>
      <Description>
        🌟 Best Transportation Hack for What The Hack 2021. Dynamic differential hourly pricing for parking lots.
        <br />
        <br />
        Made use of ESP32.
      </Description>
    </ProjectCard>
    <ProjectCard href="/projects/remember_grocery_bot">
      <ImageCard>
        <FeatureImage src={rmbgrocerybotImg} />
      </ImageCard>
      <Title>Remember Grocery Bot</Title>
      <Description>
        A convenient grocery/todo list on Telegram.
        <br />
        <br />
        Made use of Python and MongoDB, deployed on Google Cloud Functions.
      </Description>
    </ProjectCard>
    <ProjectCard href="/projects/recyclace">
      <ImageCard>
        <FeatureImage src={recyclaceImg} />
      </ImageCard>
      <Title>Recyclace</Title>
      <Description>
        Engineered the electrical and software systems for Recyclace - an
        adaptable recycling bin.
        <br />
        <br />
        Made use of Python threading, Raspberry Pi Zero, VL53L0X TOF Sensor, NEMA
        23 Servo Motor, Motor driver etc.
      </Description>
    </ProjectCard>
    <ProjectCard href="/projects/annoying_bot">
      <ImageCard>
        <FeatureImage src={annoyingBotImg} />
      </ImageCard>
      <Title>Annoying Bot</Title>
      <Description>
        A Telegram bot to remind you to submit temperature declaration everday.
        <br />
        <br />
        Made use of Node.js running on Google App Engine with Cloud Scheduler.
      </Description>
    </ProjectCard>
    <ProjectCard href="/">
      <ImageCard>
        <FeatureImage src={logoImg} />
      </ImageCard>
      <Title>iangohy.com</Title>
      <Description>
        This website!
        <br />
        <br />
        Created using Gatsby.
      </Description>
    </ProjectCard>
    <ProjectCard href="https://coin.iangohy.com">
        <ImageCard>
            <FeatureImage src={coinImg}/>
        </ImageCard>
        <Title>Coin Toss</Title>
        <Description>
          Toss a coin at coin.iangohy.com!
          <br /><br />
          Created when learning React.
        </Description>
    </ProjectCard>
    <ProjectCard href="https://ttt.iangohy.com">
        <ImageCard>
            <FeatureImage src={tttImg}/>
        </ImageCard>
        <Title>Tic-Tack-Toe</Title>
        <Description>
          Challenge your friend to a game of Tic-Tack-Toe at ttt.iangohy.com!
          <br /><br />
          Created when learning React.
          </Description>
    </ProjectCard>
    {/* <ProjectCard>
            <ImageCard>
                <FeatureImage />
            </ImageCard>
            <Title></Title>
            <Description></Description>
        </ProjectCard> */}
  </ProjectBox>
)

export default Projects
